<template>
  <div>
    <div
      class="fs-container"
      :class="{
        'full-map': fullMap,
        'is-map-view': isMapView,
        'is-properties-view': !isMapView
      }"
    >
      <!-- Map -->
      <div id="map-column" class="fs-inner-container map-column">
        <div class="fullwidth-loading-wrap" v-if="fullMap && spinnerLoading">
          <b-spinner label="Spinning" variant="primary"></b-spinner>
        </div>
        <!-- Map -->
        <div id="map-container">
          <div class="backbtns-wrap">
            <b-button @click="backBtn" id="back-btn" v-if="showBackLink">
              <span class="hi-arrow-right icon ml-2"></span>
              <span>عودة</span>
            </b-button>
          </div>
          <!--                    star mapping-->
          <div id="map">
            <gmap-map
              ref="mapRef"
              :center="mapCenter"
              :zoom="mapZoom"
              :options="options"
              style="width: 100%; height: 100%"
              @tilesloaded="onTilesLoaded"
              @zoom_changed="onZoomChanged"
              @dragend="onDraged"
            >
              <gmap-info-window
                :options="infoWindowOptions"
                :opened="infoWinOpen"
                :position="infoWindow.position"
                @closeclick="closeInfoWinOpen"
                :class="{
                  'is-active':
                    propId ===
                    (infoWindow.place_id !== null
                      ? infoWindow.place_id
                      : infoWindow.user_id)
                }"
              >
                <router-link
                  :to="{
                    name: 'agent-properties',
                    params: { id: infoWindow.id }
                  }"
                >
                  <div class="p-3 d-flex justify-content-between">
                    <div class="">
                      <h5 class="mb-3">{{ infoWindow.name }}</h5>
                      <h6>
                        <img src="@/assets/images/icons/map.png" alt="Map Image" loading="lazy"/>
                        {{ infoWindow.add }}
                      </h6>
                      <h6 class="font-14 mt-4">
                        مناطق الخدمة
                        <span class="font-14-black mr-2">
                          {{ infoWindow.vicinity }}
                        </span>
                      </h6>
                    </div>
                  </div>
                </router-link>
              </gmap-info-window>

              <gmap-custom-marker
                v-for="(property, index) in agents"
                :key="`custom-marker-${index}`"
                :ref="
                  `marker-${
                    property.place_id !== null
                      ? property.place_id
                      : property.user_id
                  }`
                "
                :id="
                  `marker-${
                    property.place_id !== null
                      ? property.place_id
                      : property.user_id
                  }`
                "
                class="map-marker"
                :class="{
                  'is-active':
                    propId ===
                      (property.place_id !== null
                        ? property.place_id
                        : property.user_id) && infoWinOpen === true,
                  'is-current':
                    (property.place_id !== null
                      ? property.place_id
                      : property.user_id) === currentPropertyId
                }"
                :marker="{
                  lat: property.geometry.location.lat,
                  lng: property.geometry.location.lng
                }"
              >
                <router-link
                  @mouseover.native="
                    toggleInfoWindow(
                      property,
                      property.place_id !== null
                        ? property.place_id
                        : property.user_id
                    )
                  "
                  @mouseout.native="closeInfoWinOpen"
                  :to="{
                    name: 'agent-properties',
                    params: { id: infoWindow.id }
                  }"
                  ref="noopener"
                >
                  <div
                    v-if="currentZoom > 12"
                    class="map-marker-price"
                    :class="{
                      'is-visited': isInVisitedProps(property.place_id)
                    }"
                  >
                    <div class="p-1">
                      <p class="text-white mb-0 font-9">{{ property.name }}</p>
                    </div>
                  </div>
                  <div
                    v-else
                    class="dot-map-marker"
                    :class="{
                      'is-visited': isInVisitedProps(property.place_id)
                    }"
                  ></div>
                </router-link>
              </gmap-custom-marker>

              <!-- Current Place Marker -->
              <gmap-custom-marker
                ref="current-property-marker"
                id="current-property-marker"
                class="map-marker is-current"
                :marker="mapCenter"
              >
                <span class="dot-map-marker"> </span>
              </gmap-custom-marker>
            </gmap-map>
          </div>
          <!-- Fullwidth toggle btn -->
          <button
            class="custom-map-button"
            id="fullwidth-btn"
            variant="light"
            :class="{ 'is-active': fullMap }"
            @click="fullMap = !fullMap"
          >
            <span class="hi-expand"></span>
          </button>

          <!-- Current Locatino btn -->
          <button class="custom-map-button" id="gps-btn" @click="geolocate()">
            <span class="hi-gps-fixed"></span>
          </button>
        </div>
      </div>
      <!-- agent listing -->
      <div
        class="fs-inner-container properties-column"
        :class="{ 'full-width-mages': fullWidthImages }"
      >
        <div class="fs-content">
          <div v-show="!isMapView">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-3">
                <input
                  v-model="agentToggal"
                  class="radio-btn"
                  type="radio"
                  name="radios"
                  id="radio2"
                  :value="true"
                  checked
                />
                <label class="h6" for="radio2"> الجهات العقارية</label>
                <input
                  v-model="agentToggal"
                  class="radio-btn"
                  type="radio"
                  name="radios"
                  id="radio1"
                  :value="false"
                />
                <label class="h6" for="radio1">عقارات</label>
              </div>
              <h3 class="agent-title">الجهات العقارية</h3>
            </div>

            <div v-if="spinnerLoading">
              <div v-for="i in 6" class="m-2" :key="i">
                <content-placeholders :rounded="true">
                  <content-placeholders-heading :img="true" />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </div>
            <div>
              <div v-if="agents.length" class="m-1">
                <div
                  class="col-md-12 p-0"
                  v-for="(agent, i) in agents"
                  :key="i"
                >
                  <listAgents
                    @mouseover.native="
                      toggleInfoWindow(
                        agent,
                        agent.place_id !== null ? agent.place_id : agent.user_id
                      )
                    "
                    @mouseout.native="closeInfoWinOpen"
                    @openHectarPlusModal="openHectarPlusModal"
                    :agent="agent"
                  />
                </div>
              </div>
              <div
                v-else
                class="
                  h-70h
                  text-center
                  p-3
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                "
              >
                <span class="hi-house-search icon font-30"></span>
                <h3 class="my-3">
                  عفواً، لا توجد مكاتب عقاريه هنا {{ listingsTitle }}
                </h3>
                <h5 class="text">حاول البحث في موقع أو حي آخر</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="map-toggle-nav">
        <!-- Current Locatino btn -->
        <button
          class="btn btn--white btn--shadow gps-btn-2"
          id="gps-btn-2"
          @click="geolocate()"
        >
          <span class="hi-gps-fixed icon"></span>
        </button>

        <b-button
          @click="isMapView = !isMapView"
          :class="{
            'map-is-active': isMapView,
            'properties-is-active': !isMapView
          }"
          variant="primary"
          class="btn btn--white btn--shadow map-toggle-btn"
        >
          <span v-if="!isMapView">
            <span class="hi-map ml-2"></span>
            <span class="text">الخريطة</span>
          </span>
          <span v-else>
            <span class="hi-apartment ml-2"></span>
            <span class="text">قائمةالمكاتب االعقارات</span>
          </span>
        </b-button>
      </div>
    </div>
    <HectarPlusModal
      v-if="dialogState"
      :dialogState="dialogState"
      @changeDialogState="changeDialogState"
    />
  </div>
</template>

<script>
import store from '../../store'
import { mapActions, mapMutations, mapState } from 'vuex'
import { gmapApi } from 'vue2-google-maps'
import { EventBus } from '@/services/EventBus'
import { realEstateService } from '../../services'
import config from '@/config'
import _ from 'lodash'
import listAgents from '../../components/agents/listAgents.vue'
import GmapCustomMarker from '@/components/map/GmapCustomMarker.vue'
// Style
import '@/styles/property/pagination.scss'
import '@/styles/property/map.scss'

function getBoundsZoomLevel(bounds, mapDim) {
  var WORLD_DIM = { height: 256, width: 256 }
  var ZOOM_MAX = 21

  function latRad(lat) {
    var sin = Math.sin((lat * Math.PI) / 180)
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
  }

  var ne = bounds.getNorthEast()
  var sw = bounds.getSouthWest()

  var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

  var lngDiff = ne.lng() - sw.lng()
  var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

  var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction)
  var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction)

  return Math.min(latZoom, lngZoom, ZOOM_MAX)
}

export default {
  layout: 'map',
  components: {
    GmapCustomMarker,
    listAgents,
    HectarPlusModal: () => import('@/components/modals/HectarPlusModal.vue')
  },
  props: ['fullMap', 'pageNumberProp'],
  data() {
    return {
      advertiser: false,
      map: null,
      firstLoad: true,
      isFirstTime: true,
      isFirstTilesLoad: true,
      showZoomInMessage: false,
      options: {
        scrollwheel: false,
        fullscreenControl: false,
        streetViewControl: false,
        minZoom: 1,
        restriction: {
          latLngBounds: {
            north: 32.17075,
            south: 16.375256,
            west: 34.501529,
            east: 55.637296
          },
          strictBounds: false
        },
        mapTypeControlOptions: {
          style: 1,
          position: 12
        },
        styles: config.mapStyles
      },
      pageNumber: 1,
      lat1: null,
      lat2: null,
      lng1: null,
      inSearchResults: false,
      currentZoom: null,
      loading: false,
      mapMarkers: [],
      infoWindow: {},
      infoWinOpen: false,
      infoWindowOptions: {
        disableAutoPan: true,
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      propId: null,
      currentPropertyId: null,
      fullWidthImages: false,
      images360IsActive: false,
      isMapView: false,
      // agents: [],
      pageToken: '',
      activeFilters: {},
      params: this.$route.query,
      agentToggal: true,
      dialogState: false
    }
  },
  watch: {
    '$route.params.id': {
      handler(id) {
        if (id == 'default') {
          this.inSearchResults = true
          this.isInfavoritesView = false
          this.isOwnerProperitesView = false
        }
      }
    },
    agentToggal(val) {
      this.setAgentLocator(val)
      !val
        ? this.$router.push({
            name: 'properties',
            params: { id: 'default' },
            query: Object.assign(this.$route.query)
          })
        : ''
    }
  },
  computed: {
    google: gmapApi,
    ...mapState('accountState', [
      'user',
      'status',
      'configs',
      'favorites',
      'realEstateStatus'
    ]),
    ...mapState('userState', ['spinnerLoading']),
    ...mapState('realEstateState', [
      'propertiesFound',
      'numberOfRealEstates',
      'pageSize',
      'mapEventsFlag',
      'mapCenter',
      'mapZoom',
      'urlQuery',
      'visitedProps'
    ]),

    ...mapState('agentLocator', ['agents', 'agentLocator']),
    showBackLink() {
      return this.$route.params.id !== 'default'
    },
    isLoggedIn() {
      return this.status.loggedIn
    },
    listingsTitle() {
      return store.get('realEstateState/listingsTitle')
    }
  },
  created() {
    if (this.mapZoom < 8) {
      this.$toasted.show(
        'الرجاء التقريب لمدينة محددة لعرض قائمة الجهات العقارية المتاحة بها',
        {
          duration: 0,
          type: 'success',
          icon: 'hi-close'
        }
      )
    }
    this.setAgents([])
    this.setMapCenterFromUrl()
    this.setMapEventsFlag(false)
    // this.setPropertiesFound([]);
    EventBus.$on('placeSelected', place => {
      if (this.map && place.types) {
        // console.log('map object is: ', this.map);
        // Viewport Bounderies
        var viewportBounds = this.map.getBounds(),
          zoom
        if (place.types.includes('country')) {
          // all the country
          zoom = 5
        } else if (place.types.includes('locality')) {
          // city
          zoom = 10
        } else if (place.types.includes('route')) {
          // street
          zoom = 14
        } else if (place.types.includes('point_of_interest')) {
          // spcial place
          zoom = 16
        } else if (
          place.types.includes('neighborhood') ||
          place.types.includes(
            'sublocality_level_1' || place.types.includes('sublocality')
          )
        ) {
          // spcial place
          zoom = 14
        } else {
          var mapConainer = document.getElementById('map-container')
          var w = mapConainer.offsetWidth
          var h = mapConainer.offsetHeight
          zoom = getBoundsZoomLevel(viewportBounds, { width: w, height: h })
        }
        this.map.setZoom(zoom)
      }
    })
    EventBus.$on('on-toggle-infoWindow', (property, id) => {
      this.toggleInfoWindow(property, id)
    })
    EventBus.$on('on-close-infoWindow', () => {
      this.closeInfoWinOpen()
    })
    this.getAgents(this.params)
  },
  updated() {},
  mounted() {
    // sync pageNumber with url parameters
    let currentUrlObject = realEstateService.getCurrentUrlParamsObj()
    this.pageNumber = currentUrlObject.pageNumber
      ? currentUrlObject.pageNumber
      : 1
    EventBus.$on('onDragMap', () => {
      this.pageNumber = 1
    })
    this.$refs.mapRef.$mapPromise.then(() => {
      if (this.$route.name === 'agents') this.map = this.$refs.mapRef.$mapObject
    })
  },
  methods: {
    ...mapActions('realEstateState', ['getRealEstate', 'setUrlQuery']),
    ...mapActions('accountState', ['getFavorites']),
    ...mapMutations('accountState', ['setFavorites']),
    ...mapMutations('userState', ['setSpinnerLoading']),
    ...mapMutations('realEstateState', [
      'setNumberOfRealEstates',
      'setPropertiesFound',
      'setPropertiesTitle',
      'setMapCenter',
      'setMapEventsFlag'
    ]),
    ...mapActions('agentLocator', ['getAgents']),
    ...mapMutations('agentLocator', ['setAgentLocator', 'setAgents']),
    toggleInfoWindow: function(property, id) {
      console.log('agent details', id)
      let position = {
        lat: property.geometry.location.lat,
        lng: property.geometry.location.lng
      }
      this.infoWindow.position = position
      this.infoWindow.id =
        property.place_id !== null ? property.place_id : property.user_id
      this.infoWindow.name = property.name
      this.infoWindow.vicinity = property.vicinity
      this.convertLatlngToAddress(property.geometry.location)
      // this.infoWindow.formatted_address = property.formatted_address;

      let markerPosition = { position }
      let marker = new window.google.maps.Marker(markerPosition)

      this.infoWindowOptions.pixelOffset = this.getInfowindowOffset(marker)

      //check if its the same marker that was selected if yes toggle
      if (this.propId == id) {
        this.infoWinOpen = !this.infoWinOpen
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true
        this.propId = id
        // console.log(this.propId);
      }
    },
    closeInfoWinOpen() {
      this.infoWinOpen = false
    },
    getInfowindowOffset: function(marker) {
      if (this.map) {
        var center = this.getPixelFromLatLng(this.map.getCenter()),
          point = this.getPixelFromLatLng(marker.getPosition()),
          quadrant = '',
          offset
        quadrant += point.y > center.y ? 'b' : 't'
        quadrant += point.x < center.x ? 'l' : 'r'
        if (quadrant == 'tr') {
          offset = new window.google.maps.Size(-140, 245)
        } else if (quadrant == 'tl') {
          offset = new window.google.maps.Size(140, 245)
        } else if (quadrant == 'br') {
          offset = new window.google.maps.Size(-140, -45)
        } else if (quadrant == 'bl') {
          offset = new window.google.maps.Size(140, -45)
        }
        return offset
      }
    },
    getPixelFromLatLng: function(latLng) {
      var projection = this.map.getProjection()
      var point = projection.fromLatLngToPoint(latLng)
      return point
    },
    async onDraged() {
      this.setAgents([])
      this.setSpinnerLoading(true) // Begin Loading
      this.currentZoom = this.map.getZoom()
      // 1- Set parmas for generate query
      this.setMapCenterFromMap() // to set lat, lng in url

      // Set lat lng from map center
      let { lat, lng } = this.mapCenter // From state
      this.activeFilters.lat = lat
      this.activeFilters.lng = lng

      // 2- Generate query & Update url
      await this.getAgents(this.activeFilters)

      // 4- Set properties title depend on lat,lng form url
      // let{lat, lng}= realEstateService.getCurrentUrlParamsObj();
      this.convertLatlngToAddress({ lat: Number(lat), lng: Number(lng) })
    },
    onTilesLoaded() {
      if (!this.mapEventsFlag) {
        this.onDraged()
        this.setMapEventsFlag(true)
      }
    },
    onZoomChanged(zoom) {
      if (zoom < 8) {
        this.$toasted.clear()
        this.$toasted.show(
          'الرجاء التقريب لمدينة محددة لعرض قائمة الجهات العقارية المتاحة بها',
          {
            duration: 0,
            type: 'success',
            icon: 'hi-close'
          }
        )
      } else {
        this.$toasted.clear()
      }
      if (this.mapEventsFlag) {
        this.currentZoom = zoom
        this.onDraged()
      }
    },
    convertLatlngToAddress(location) {
      // Convert the latlang to formatted address
      if (location) {
        let geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ latLng: location }, results => {
          if (results === null) return
          // var user_city = results[0].address_components.filter(ac => ~ac.types.indexOf('locality'))[0].long_name;
          // console.log(results[0], user_city);
          this.infoWindow.add = results[0].formatted_address
        })
      }
    },
    backBtn() {
      // debugger;
      this.$router.go(-1)
      // fix the backBtn issue, if you back to search results page.
      // Still want more clear and maintanable fix :(
      setTimeout(() => {
        if (this.$route.params.id == 'default') {
          this.onDraged()
        }
      }, 100)
    },
    setMapCenterFromMap() {
      // Set map center in state
      let lat = this.map.center.lat()
      let lng = this.map.center.lng()
      this.setMapCenter({ lat, lng })
    },
    setMapCenterFromUrl() {
      let { lat, lng } = realEstateService.getCurrentUrlParamsObj()
      if (lat && lng) {
        let center = { lat: Number(lat), lng: Number(lng) }
        this.setMapCenter(center)
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        let center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        this.setMapCenter(center)
      })
    },
    setCurrentMarker(id) {
      this.currentPropertyId = id
    },
    setFullWidthImages(val) {
      if (val === 'close') {
        this.fullWidthImages = false
      } else {
        this.fullWidthImages = !this.fullWidthImages
      }
    },
    hasPurpose(type) {
      return realEstateService.hasPurpose(type)
    },
    numberFormatter(num, digits) {
      return realEstateService.numberFormatter(num, digits)
    },
    numberWithCommas(x) {
      return realEstateService.numberWithCommas(x)
    },

    isInVisitedProps(id) {
      return this.visitedProps.includes(id)
    },

    generateUrlParm: _.debounce(function(filters) {
      this.setSpinnerLoading(true)
      filters = {
        ...filters,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber
      }
      realEstateService.generateQuery(filters).then(query => {
        this.getRealEstate(query)
      })
    }, 500),

    addNewProperty() {
      var isLoggedInFromStorage = localStorage.getItem('logged_in')
      if (isLoggedInFromStorage !== 'true') {
        this.$toasted.show('من فضلك قم بتسجيل الدخول أولاً', {
          duration: 4000
        })
        if (this.$route.name !== 'login') this.$router.push({ name: 'login' })
      } else if (this.user && this.user.type === 'seeker')
        this.$toasted.show(
          'نوع المستخدم باحث عن عقار لا يمكنه إضافة عقارات، فضلاً قم بتغيبر نوع الحساب من إعدادات ملفك الشخصي',
          { duration: 4000 }
        )
      else {
        if (this.$route.name !== 'addProperty')
          this.$router.push({ name: 'addProperty', params: { id: 'new' } })
      }
    },
    openHectarPlusModal() {
      this.dialogState = true
    },
    changeDialogState(value) {
      this.dialogState = value
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setSpinnerLoading(true)
    // Begin Loading
    next()
  },
  beforeDestroy() {
    this.setNumberOfRealEstates(0)
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/agents/index';
</style>
