<template>
  <div>
    <div
      class="card-1 my-2 mx-2"
      @mouseenter="
        onToggleInfoWindow(
          agent,
          agent.place_id !== null ? agent.place_id : agent.user_id
        )
      "
      @mouseleave="closeInfoWindo()"
    >
      <router-link
        :to="{
          name: 'agent-properties',
          params: { id: agent.place_id ? agent.place_id : agent.user_id }
        }"
      >
        <div class="d-flex justify-content-between agent-hover">
          <div class="p-24">
            <h5 class="mb-md-3" :class="{ 'mb-1': mq === 'xs' }">
              {{ agent.name }}
            </h5>
            <span
              role="button"
              @click.stop.prevent="openHectarPlusModal"
              :class="['hectar-plus', hectarPlusSubscribedBorder]"
            >
              <span :class="['title', hectarPlusSubscribedColor]">
                {{ hectarPlusSubscribedText }}
              </span>
              <img
                class="mr-1"
                :src="hectarPlusSubscribedIcon"
                alt="subscribed"
                v-if="hectarPlusSubscribed"
              />
              <img
                v-else
                class="mr-1"
                :src="hectarPlusUnsubscribedIcon"
                alt="unsubscribed"
              />
            </span>
            <div class="rating-wrap justify-content-start my-1">
              <rate
                :value="Math.round(agent.rating)"
                :readonly="true"
                id="experience-rating"
                :length="5"
              />
            </div>
            <div class=" mt-3  d-flex  align-items-center">
              <a
                @click.once.prevent="here('phone')"
                class="list-tags font-12 text-white"
              >
                <img
                  src="@/assets/images/icons/call.png"
                  alt="call"
                  loading="lazy"
                />
              </a>
              <a
                class="list-tags-outline font-12 brand-color"
                @click.once.prevent="here('whatsapp')"
              >
                <img
                  src="@/assets/images/icons/what.png"
                  alt="what"
                  loading="lazy"
                />
              </a>
              <!-- <router-link
                v-if="userData"
                :to="
                  `/messaging?receiver=${encodeURIComponent(
                    JSON.stringify(receiver)
                  )}`
                "
                class="list-tags-outline font-12 brand-color"
              >
                <img :src="messagesIcon" class="messages-icon" alt="messages" />
              </router-link> -->
              <a
                class="list-tags-outline font-12 brand-color"
                @click.stop.once="here()"
                v-if="Object.keys(agent.geometry.location)"
                target="_blank"
                :href="
                  `http://www.google.com/maps/place/${agent.geometry.location.lat},${agent.geometry.location.lng}`
                "
              >
                <img
                  src="@/assets/images/icons/contact.png"
                  alt="loaction"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
          <div class="h-100-24">
            <div
              class="side-card padd-listing h-100-24"
              :class="{ 'side-card-xs': mq === 'xs' }"
            >
              <h5 class="font-14">للبيع</h5>
              <h5 v-if="agent.forSaleCount">
                {{ agent.forSaleCount }}<span class=" mr-1">عقار</span>
              </h5>
              <h5 v-else>لا يوجد</h5>
            </div>
            <div
              class="side-card padd-listing h-100-24"
              :class="{ 'side-card-xs': mq === 'xs' }"
            >
              <h5 class="font-14">للايجار</h5>
              <h5 v-if="agent.forRentCount">
                {{ agent.forRentCount }}<span class="mr-1">عقار</span>
              </h5>
              <h5 v-else>لا يوجد</h5>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'
import { mapState } from 'vuex'
import { EventBus } from '@/services/EventBus'
//Style
import '@/styles/property/rating-block.scss'

export default {
  name: 'listAgents',
  props: ['agent'],
  data() {
    return {
      fav: true,
      details: {},
      formatted_address: '',
      rateValue: 0,
      messagesIcon: require('../../assets/images/svgs/messages-icon.svg'),
      hectarPlusSubscribedIcon: require('@/assets/images/svgs/checkmark.svg'),
      hectarPlusUnsubscribedIcon: require('@/assets/images/svgs/close-icon.svg'),
      userData: JSON.parse(localStorage.getItem('tokenData'))
    }
  },
  computed: {
    ...mapState('accountState', ['user']),
    mq() {
      return this.$mq
    },
    formatted() {
      return this.details?.formatted_address
        ? this.details.formatted_address
        : this.agent.formatted_address
    },
    vicinity() {
      return this.agent?.vicinity ? this.agent.vicinity : 'لا يوجد'
    },
    international_phone_number() {
      return this.details?.international_phone_number
        ? this.details.international_phone_number
        : this.agent.international_phone_number
    },
    showName() {
      return this.details?.name ? this.details.name : this.agent.name
    },
    hectarPlusSubscribed() {
      return this.agent.business_status !== 'OPERATIONAL'
    },
    hectarPlusSubscribedText() {
      if (this.hectarPlusSubscribed) return 'مشترك في هكتار بلس'
      else return 'غير مشترك في هكتار بلس'
    },
    hectarPlusSubscribedColor() {
      if (this.hectarPlusSubscribed) return 'hectar-plus-subscribed-color'
      else return 'hectar-plus-unsubscribed-color'
    },
    hectarPlusSubscribedBorder() {
      if (this.hectarPlusSubscribed) return 'hectar-plus-subscribed-border'
      else return 'hectar-plus-unsubscribed-border'
    }
    // receiver() {
    //   return {
    //     receiver: {
    //       _id: this.agent.user_id,
    //       name: this.agent.name,
    //       phoneNumber: this.agent.international_phone_number,
    //     },
    //     subjectId: this.agent.user_id,
    //   };
    // },
  },
  async created() {
    await this.agent
  },

  methods: {
    async PlaceDetails() {
      await axios
        .get(
          `${config.apiUrl}/get-realestate-agent-details?placeId=${this.agent.place_id}`
        )
        .then(res => {
          this.details = res.data.result
        })
    },
    onToggleInfoWindow: function(property, id) {
      if (this.usedIn == 'properties')
        EventBus.$emit('on-toggle-infoWindow', property, id)
    },
    closeInfoWindo() {
      if (this.usedIn == 'properties') EventBus.$emit('on-close-infoWindow')
    },
    async here(str) {
      await this.PlaceDetails().then(() => {
        if (str === 'whatsapp') {
          return window.open(
            `https://api.whatsapp.com/send?phone=${this.international_phone_number}&text=مرحباً ${this.showName}`,
            '_blank'
          )
        }
        if (str === 'phone') {
          window.open(`tel:+${this.international_phone_number}`, '_blank')
        }
      })
    },
    openHectarPlusModal() {
      this.$emit('openHectarPlusModal')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/agents/index';
</style>
